import UrlPattern from "url-pattern";

// Wildcard Match URLs //
export const programBaseUrl = new UrlPattern("/programs/:programId/*");
export const adminProgramBaseUrl = new UrlPattern(
  "/admin/programs/:programId(/*)",
);
export const competencyBaseUrl = new UrlPattern(
  "*/programs/:programId/competencies/:competencyId(/*)",
);
export const opportunityBaseUrl = new UrlPattern(
  "*/programs/:programId/opportunities/:opportunityId/*",
);
export const userBaseUrl = new UrlPattern(
  "*/programs/:programId/users/:userId/*",
);
export const adminLevelRequestBaseUrl = new UrlPattern(
  "*/programs/:programId/level-requests/:objectId*",
);

// Interface URLs //
export const programBadgesUrl = new UrlPattern("/programs/:programId/badges");
export const programCareersUrl = new UrlPattern("/programs/:programId/careers");
export const programHomepageUrl = new UrlPattern(
  "/programs/:programId/homepage",
);
export const programAboutUrl = new UrlPattern("/programs/:programId/about");
export const programOpportunitiesUrl = new UrlPattern(
  "/programs/:programId/opportunities",
);
export const programPlannerUrl = new UrlPattern(
  "/programs/:programId/opportunities/planner",
);
export const programOpportunityDetailUrl = new UrlPattern(
  "/programs/:programId/opportunities/:opportunityId",
);
export const programOpportunityReflectionEditUrl = new UrlPattern(
  "/programs/:programId/opportunities/:objectId/reflection/:reflectionId",
);
export const programOpportunityReflectionUrl = new UrlPattern(
  "/programs/:programId/my-reflections/:reflectionId",
);
export const programCompetenciesUrl = new UrlPattern(
  "/programs/:programId/competencies",
);
export const programCompetencyDetailUrl = new UrlPattern(
  "/programs/:programId/competencies/:competencyId",
);
export const programCompetencyUserProgressUrl = new UrlPattern(
  "/programs/:programId/competencies/:competencyId/my-progress",
);
export const programUserProfileUrl = new UrlPattern(
  "/programs/:programId/user",
);
export const logoutUrl = "/logout";
export const overviewUrl = "/overview";

export const programOpportunityStudentCreateUrl = new UrlPattern(
  "/programs/:programId/my-opportunities/create",
);
export const programOpportunityStudentEditUrl = new UrlPattern(
  "/programs/:programId/my-opportunities/:opportunityId/edit",
);

export const programOnboardingUrl = new UrlPattern(
  "/programs/:programId/onboarding",
);
export const programWelcomeUrl = new UrlPattern("/programs/:programId/welcome");
export const programSurveyRouterUrl = new UrlPattern(
  "/programs/:programId/survey-router",
);
export const programSurveyUrl = new UrlPattern(
  "/programs/:programId/survey/:surveyId",
);
export const programExerciseRouterUrl = new UrlPattern(
  "/programs/:programId/exercise-router/",
);
export const programPreferenceExerciseUrl = new UrlPattern(
  "/programs/:programId/preference-exercise/:exerciseId",
);
export const programPreferenceSelectionUrl = new UrlPattern(
  "/programs/:programId/preference-selection",
);
export const programStatusUrl = new UrlPattern("/programs/:programId/status");
export const programCompetencyLevelSetUrl = new UrlPattern(
  "/programs/:programId/competency-levelset",
);

export const adminProgramBadgesUrl = new UrlPattern(
  "/admin/programs/:programId/badges",
);
export const adminProgramCareersUrl = new UrlPattern(
  "/admin/programs/:programId/careers",
);
export const adminProgramOverviewUrl = new UrlPattern(
  "/admin/programs/overview",
);
export const adminProgramDashboardUrl = new UrlPattern(
  "/admin/programs/:programId/dashboard",
);
export const adminProgramConfigurationUrl = new UrlPattern(
  "/admin/programs/:programId/configuration",
);
export const adminProgramOpportunitiesUrl = new UrlPattern(
  "/admin/programs/:programId/opportunities",
);
export const adminProgramOpportunityDetailUrl = new UrlPattern(
  "/admin/programs/:programId/opportunities/:opportunityId",
);
export const adminProgramOpportunityEditUrl = new UrlPattern(
  "/admin/programs/:programId/opportunities/:opportunityId/edit",
);
export const adminProgramCompetenciesUrl = new UrlPattern(
  "/admin/programs/:programId/competencies",
);
export const adminProgramCompetencyDetailUrl = new UrlPattern(
  "/admin/programs/:programId/competencies/:competencyId",
);
export const adminProgramCompetencyEditUrl = new UrlPattern(
  "/admin/programs/:programId/competencies/:competencyId/edit",
);
export const adminProgramDimensionDetailUrl = new UrlPattern(
  "/admin/programs/:programId/dimensions/:dimensionId",
);
export const adminProgramDimensionEditUrl = new UrlPattern(
  "/admin/programs/:programId/dimensions/:dimensionId/edit",
);
export const adminProgramLevelDetailUrl = new UrlPattern(
  "/admin/programs/:programId/levels/:levelId",
);
export const adminProgramLevelEditUrl = new UrlPattern(
  "/admin/programs/:programId/levels/:levelId/edit",
);
export const adminProgramExercisesUrl = new UrlPattern(
  "/admin/programs/:programId/exercises",
);
export const adminProgramExerciseDetailUrl = new UrlPattern(
  "/admin/programs/:programId/exercises/:exerciseId",
);
export const adminProgramExerciseEditUrl = new UrlPattern(
  "/admin/programs/:programId/exercises/:exerciseId/edit",
);
export const adminProgramMembersUrl = new UrlPattern(
  "/admin/programs/:programId/members",
);
export const adminProgramMemberDetailUrl = new UrlPattern(
  "/admin/programs/:programId/members/:objectId",
);
export const adminProgramCohortsUrl = new UrlPattern(
  "/admin/programs/:programId/cohorts",
);
export const adminProgramCohortCreateUrl = new UrlPattern(
  "/admin/programs/:programId/cohorts/create",
);
export const adminProgramCohortDetailUrl = new UrlPattern(
  "/admin/programs/:programId/cohorts/:cohortId",
);
export const adminProgramCohortEditUrl = new UrlPattern(
  "/admin/programs/:programId/cohorts/:cohortId/edit",
);
export const adminProgramTimeframesUrl = new UrlPattern(
  "/admin/programs/:programId/timeframes",
);
export const adminProgramMembersActivityUrl = new UrlPattern(
  "/admin/programs/:programId/user_activity",
);
export const adminProgramUserProfileUrl = new UrlPattern(
  "/admin/programs/:programId/user",
);
export const adminLevelRequestsUrl = new UrlPattern(
  "/admin/programs/:programId/level-requests",
);
export const adminLevelRequestDetailUrl = new UrlPattern(
  "/admin/programs/:programId/level-requests/:objectId",
);
export const adminLevelRequestReviewUrl = new UrlPattern(
  "/admin/programs/:programId/level-requests/:objectId/review",
);
export const adminAnnouncementDetailUrl = new UrlPattern(
  "/admin/programs/:programId/announcements/:announcementId",
);
export const adminProgramOnboardingSlidesUrl = new UrlPattern(
  "/admin/programs/:programId/onboarding_slides",
);
export const adminProgramAnalyticsUrl = new UrlPattern(
  "/admin/programs/:programId/analytics",
);

export const programECoachSurveyListUrl = new UrlPattern(
  "/programs/:programId/ecoach/surveys",
);
export const programECoachSurveyUrl = new UrlPattern(
  "/programs/:programId/ecoach/surveys/:surveyId",
);
export const programECoachSurveyCreateUrl = new UrlPattern(
  "/programs/:programId/ecoach/surveys/:surveyId",
);
export const programECoachSurveyUpdateUrl = new UrlPattern(
  "/programs/:programId/ecoach/surveys/:surveyId",
);

export const programLevelRequestCreateUrl = new UrlPattern(
  "/programs/:programId/competencies/:competencyId/dimensions/:dimensionId/level-selection",
);
export const participantFacingLevelRequestListUrl = new UrlPattern(
  "/programs/:programId/level_up_requests",
);
export const programLevelRequestDetailUrl = new UrlPattern(
  "/programs/:programId/level-requests/:objectId",
);
export const programLevelRequestEditUrl = new UrlPattern(
  "/programs/:programId/level-requests/:objectId/edit",
);
export const programLevelRequestReviewUrl = new UrlPattern(
  "/programs/:programId/level-requests/:objectId/review",
);

export const programInitialAssessmentEditUrl = new UrlPattern(
  "/programs/:programId/initial-assessment/:objectId/edit",
);
export const programInitialAssessmentReviewUrl = new UrlPattern(
  "/programs/:programId/initial-assessment/:objectId/review",
);

// V2 API URLs //
export const v2AdminProgramCompetencyListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/competencies",
);
export const v2AdminProgramCompetencyDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/competencies/:competencyId",
);
export const v2AdminProgramCompetencyCopyUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/competencies/:competencyId/copy",
);

export const v2AdminProgramDimensionListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/dimensions",
);
export const v2AdminProgramDimensionDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/dimensions/:dimensionId",
);
export const v2AdminProgramDimensionCopyUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/dimensions/:dimensionId/copy",
);

export const v2AdminProgramLevelListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/levels",
);
export const v2AdminProgramLevelOptionListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/levels/options",
);
export const v2AdminProgramLevelDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/levels/:levelId",
);

export const v2AdminProgramReflectionPromptListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/reflection_prompts",
);
export const v2AdminProgramReflectionPromptDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/reflection_prompts/:promptId",
);

export const v2AdminProgramBadgeListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/badges",
);
export const v2AdminProgramBadgeUpdateUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/badges/:badgeId",
);

export const v2AdminProgramBadgeRecipientsUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/badges/:badgeId/recipients",
);
export const v2AdminProgramBadgeNonrecipientsUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/badges/:badgeId/nonrecipients",
);

export const v2AdminProgramBadgeAwardManyPostUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/earned_badges/bulk_create",
);
export const v2AdminProgramEarnedBadgeDestoryPostUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/earned_badges/:badgeId",
);

export const v2StudentProgramBadgeListUrl = new UrlPattern(
  "/api/v2/student/programs/:programId/badges",
);

export const v2StudentProgramBadgeDetailUrl = new UrlPattern(
  "/api/v2/student/programs/:programId/badges/:badgeId",
);

export const v2StudentAchievementList = new UrlPattern(
  "/api/v2/student/programs/:programId/achievements",
);

export const v2AdminProgramOpportunityListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/opportunities",
);

export const v2AdminProgramOpportunityOptionsUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/opportunities/options",
);

export const v2AdminProgramOnboardingSlideListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/onboarding_slides",
);
export const v2AdminProgramOnboardingSlideBulkCreateUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/onboarding_slides/bulk_create",
);
export const v2AdminProgramOnboardingSlideDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/onboarding_slides/:slideId",
);
export const v2AdminProgramOnboardingSlideReorderUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/onboarding_slides/reorder",
);
export const v2AdminProgramOnboardingSlideImageUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/onboarding_slides/:slideId/image",
);
export const v2StudentProgramOnboardingSlideListUrl = new UrlPattern(
  "/api/v2/student/programs/:programId/onboarding_slides",
);

export const v2AdminProgramOpportunityCategoryListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/opportunity_categories",
);
export const v2AdminProgramOpportunityCategoryDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/opportunity_categories/:opportunityCategoryId",
);

export const v2AdminProgramEffortLevelListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/effort_levels",
);
export const v2AdminProgramEffortLevelDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/effort_levels/:effortLevelId",
);

export const v2AdminAchievementIssuerDetailUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/achievement_issuer",
);

export const v2AdminProgramAnalyticsListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/analytics",
);

export const v2AdminProgramCohortListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/cohorts",
);
export const v2AdminProgramCohortUpdateUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/cohorts/:cohortId",
);

export const v2AdminProgramTimeframeListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/timeframes",
);

export const v2AdminProgramTimeframeUpdateUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/timeframes/:timeframeId",
);

export const v2AdminProgramExerciseListUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/ranking_exercises",
);

export const v2AdminProgramExerciseUpdateUrl = new UrlPattern(
  "/api/v2/admin/programs/:programId/ranking_exercises/:exerciseId",
);

// API URLs //
export const programContactOptionsUrl = new UrlPattern(
  "/api/programs/program_contact_options",
);
export const userDataUrl = new UrlPattern("/api/programs/userdata");
export const programUserDataUrl = new UrlPattern(
  "/api/programs/:programId/userdata",
);
export const programUserDataNavigationCountUrl = new UrlPattern(
  "/api/programs/:programId/userdata/navigation_counts",
);
export const userProgramDataUrl = new UrlPattern(
  "/api/programs/top_level_dashboard",
);

export const programListUrl = new UrlPattern("/api/programs");
export const programCreateUrl = new UrlPattern("/api/programs/create");
export const programDataUrl = new UrlPattern(
  "/api/programs/:programId/details",
);
export const programUpdateUrl = new UrlPattern(
  "/api/programs/:programId/update",
);
export const programUploadImagesUrl = new UrlPattern(
  "/api/programs/:programId/images/upload",
);
export const programRemoveImagesUrl = new UrlPattern(
  "/api/programs/:programId/images/remove",
);
export const programOnboardingCompletedUrl = new UrlPattern(
  "/api/programs/:programId/complete-onboarding",
);

export const programUrls = {
  list: programListUrl,
  create: programCreateUrl,
  details: programDataUrl,
  update: programUpdateUrl,
  images: programUploadImagesUrl,
  removeImages: programRemoveImagesUrl,
};

export const programIntegrationsListUrl = new UrlPattern(
  "/api/programs/:programId/integrations",
);
export const programIntegrationsCreateUrl = new UrlPattern(
  "/api/programs/:programId/integrations/create",
);
export const programIntegrationsDetailUrl = new UrlPattern(
  "/api/programs/:programId/integrations/:objectId/details",
);
export const programIntegrationsUpdateUrl = new UrlPattern(
  "/api/programs/:programId/integrations/:objectId/update",
);

export const programIntegrationUrls = {
  list: programIntegrationsListUrl,
  create: programIntegrationsCreateUrl,
  details: programIntegrationsDetailUrl,
  update: programIntegrationsUpdateUrl,
};

export const programTailoringReferenceListUrl = new UrlPattern(
  "/api/programs/:programId/tailoring/references",
);
export const programTailoringReferenceCreateUrl = new UrlPattern(
  "/api/programs/:programId/tailoring/references/create",
);
export const programTailoringReferenceDetailUrl = new UrlPattern(
  "/api/programs/:programId/tailoring/references/:objectId/details",
);
export const programTailoringReferenceUpdateUrl = new UrlPattern(
  "/api/programs/:programId/tailoring/references/:objectId/update",
);
export const programTailoringReferenceContentUrl = new UrlPattern(
  "/api/programs/:programId/tailoring/references/:objectId/content",
);
export const programTailoringSurveyContentUrl = new UrlPattern(
  "/api/programs/:programId/tailoring/references/:objectId/survey",
);

export const tailoringUrls = {
  project: {
    list: new UrlPattern("/api/programs/:programId/tailoring/projects"),
  },
  coach: {
    list: new UrlPattern("/api/programs/:programId/tailoring/coaches"),
  },
  document: {
    list: new UrlPattern("/api/programs/:programId/tailoring/documents"),
  },
  message: {
    list: new UrlPattern("/api/programs/:programId/tailoring/messages"),
  },
  survey: {
    list: new UrlPattern("/api/programs/:programId/tailoring/surveys"),
    content: programTailoringSurveyContentUrl,
  },
  reference: {
    list: programTailoringReferenceListUrl,
    create: programTailoringReferenceCreateUrl,
    details: programTailoringReferenceDetailUrl,
    update: programTailoringReferenceUpdateUrl,
    content: programTailoringReferenceContentUrl,
  },
};

export const institutionalDataUrls = {
  terms: {
    list: new UrlPattern("/api/programs/:programId/external/ecoach/terms"),
  },
  courses: {
    list: new UrlPattern("/api/programs/:programId/external/ecoach/courses"),
  },
  courseGrade: {
    list: new UrlPattern(
      "/api/programs/:programId/external/ecoach/course-grades",
    ),
  },
  canvasGradeBook: {
    list: new UrlPattern(
      "/api/programs/:programId/external/ecoach/course-gradebooks",
    ),
  },
  canvasAssignment: {
    list: new UrlPattern(
      "/api/programs/:programId/external/ecoach/course-assignments",
    ),
  },
  canvasGrade: {
    list: new UrlPattern(
      "/api/programs/:programId/external/ecoach/assignment-grades",
    ),
  },
};

export const programAnnouncementsListUrl = new UrlPattern(
  "/api/programs/:programId/announcements",
);
export const programAnnouncementsCreateUrl = new UrlPattern(
  "/api/programs/:programId/announcements/create",
);
export const programAnnouncementsDetailUrl = new UrlPattern(
  "/api/programs/:programId/announcements/:objectId/details",
);
export const programAnnouncementsUpdateUrl = new UrlPattern(
  "/api/programs/:programId/announcements/:objectId/update",
);
export const programAnnouncementRecipientsUrl = new UrlPattern(
  "/api/programs/:programId/announcements/:objectId/recipients",
);
export const programAnnouncementPreviewUrl = new UrlPattern(
  "/api/programs/:programId/announcements/:objectId/recipients/:recipientId/preview",
);

export const programAnnouncementUrls = {
  list: programAnnouncementsListUrl,
  create: programAnnouncementsCreateUrl,
  details: programAnnouncementsDetailUrl,
  update: programAnnouncementsUpdateUrl,
};

export const programScheduledAnnouncementsListUrl = new UrlPattern(
  "/api/programs/:programId/scheduled-announcements",
);
export const programScheduledAnnouncementsDetailUrl = new UrlPattern(
  "/api/programs/:programId/scheduled-announcements/:objectId",
);

export const programMembersListUrl = new UrlPattern(
  "/api/programs/:programId/members",
);
export const programMembersUnpaginatedSearchUrl = new UrlPattern(
  "/api/programs/:programId/unpaginated-members",
);
export const programMembersCreateUrl = new UrlPattern(
  "/api/programs/:programId/members/create",
);
export const programMembersUpdateUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/update",
);

export const programMemberDetailUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId",
);
export const programMemberCompetenciesUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/competencies",
);
export const programMemberOpportunitiesUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/opportunities",
);
export const programMemberLevelRequestsUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/level_requests",
);
export const programMemberAdminCompetencyProgressUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/admin-competency-progress",
);
export const programMemberStudentCompetencyProgressUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/student-competency-progress",
);
export const programMemberUpdateLevelUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/update-level",
);
export const programMemberDeleteLevelUrl = new UrlPattern(
  "/api/programs/:programId/members/:objectId/delete-level",
);

export const programMemberUrls = {
  list: programMembersListUrl,
  create: programMembersCreateUrl,
  update: programMembersUpdateUrl,
  details: programMemberDetailUrl,
  competency: {
    list: programMemberCompetenciesUrl,
    update: programMemberUpdateLevelUrl,
    delete: programMemberDeleteLevelUrl,
    adminProgress: programMemberAdminCompetencyProgressUrl,
    studentProgress: programMemberStudentCompetencyProgressUrl,
  },
  opportunity: {
    list: programMemberOpportunitiesUrl,
  },
  levelRequest: {
    list: programMemberLevelRequestsUrl,
  },
};

export const programCompetenciesListUrl = new UrlPattern(
  "/api/programs/:programId/competencies",
);
export const programCompetenciesCreateUrl = new UrlPattern(
  "/api/programs/:programId/competencies/create",
);
export const programCompetenciesDetailUrl = new UrlPattern(
  "/api/programs/:programId/competencies/:competencyId/details",
);
export const programCompetenciesUpdateUrl = new UrlPattern(
  "/api/programs/:programId/competencies/:competencyId/update",
);
export const programCompetencyActivitiesUrl = new UrlPattern(
  "/api/programs/:programId/competencies/:competencyId/activities",
);
export const programCompetencyLevelsSummaryUrl = new UrlPattern(
  "/api/programs/:programId/competencies/download-levels-summary.csv",
);

export const programCompetencyUrls = {
  list: programCompetenciesListUrl,
  create: programCompetenciesCreateUrl,
  details: programCompetenciesDetailUrl,
  update: programCompetenciesUpdateUrl,
};

export const programOpportunityCategoriesListUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/categories",
);
export const programOpportunityCategoriesCreateUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/categories/create",
);
export const programOpportunityCategoriesDetailUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/categories/:objectId",
);
export const programOpportunityCategoriesUpdateUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/categories/:objectId/update",
);

export const programOpportunityCategoriesUrls = {
  list: programOpportunityCategoriesListUrl,
  create: programOpportunityCategoriesCreateUrl,
  details: programOpportunityCategoriesDetailUrl,
  update: programOpportunityCategoriesUpdateUrl,
};

export const programOpportunitiesListUrl = new UrlPattern(
  "/api/programs/:programId/opportunities",
);
export const programOpportunitiesCreateUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/create",
);
export const programOpportunitiesDetailUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/:objectId/details",
);
export const programOpportunitiesUpdateUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/:objectId/update",
);

export const programOpportunityUrls = {
  list: programOpportunitiesListUrl,
  create: programOpportunitiesCreateUrl,
  details: programOpportunitiesDetailUrl,
  update: programOpportunitiesUpdateUrl,
};

export const programUsersListUrl = new UrlPattern(
  "/api/programs/:programId/users",
);
export const programUsersCreateUrl = new UrlPattern(
  "/api/programs/:programId/users/create",
);
export const programUsersDetailUrl = new UrlPattern(
  "/api/programs/:programId/users/:userId",
);
export const programUsersUpdateUrl = new UrlPattern(
  "/api/programs/:programId/users/:userId/update",
);

export const programUserUrls = {
  list: programUsersListUrl,
  create: programUsersCreateUrl,
  details: programUsersDetailUrl,
  update: programUsersUpdateUrl,
};

export const competencyLevelsListUrl = new UrlPattern(
  "/api/programs/:programId/competencies/levels",
);
export const competencyLevelsCreateUrl = new UrlPattern(
  "/api/programs/:programId/competencies/:competencyId/levels/create",
);
export const competencyLevelsDetailUrl = new UrlPattern(
  "/api/programs/:programId/competencies/:competencyId/levels/:objectId",
);
export const competencyLevelsUpdateUrl = new UrlPattern(
  "/api/programs/:programId/competencies/:competencyId/levels/:objectId/update",
);

export const competencyLevelUrls = {
  list: competencyLevelsListUrl,
  create: competencyLevelsCreateUrl,
  details: competencyLevelsDetailUrl,
  update: competencyLevelsUpdateUrl,
};

export const programEffortListUrl = new UrlPattern(
  "/api/programs/:programId/effort",
);
export const programEffortCreateUrl = new UrlPattern(
  "/api/programs/:programId/effort/create",
);
export const programEffortDetailUrl = new UrlPattern(
  "/api/programs/:programId/effort/:objectId/",
);
export const programEffortUpdateUrl = new UrlPattern(
  "/api/programs/:programId/effort/:objectId/update",
);

export const programEffortUrls = {
  list: programEffortListUrl,
  create: programEffortCreateUrl,
  details: programEffortDetailUrl,
  update: programEffortUpdateUrl,
};

export const programTimeframesListUrl = new UrlPattern(
  "/api/programs/:programId/timeframes",
);
export const paginatedProgramTimeframesListUrl = new UrlPattern(
  "/api/programs/:programId/paginated-timeframes",
);
export const programTimeframesCreateUrl = new UrlPattern(
  "/api/programs/:programId/timeframes/create",
);
export const programTimeframesDetailUrl = new UrlPattern(
  "/api/programs/:programId/timeframes/:objectId/",
);
export const programTimeframesUpdateUrl = new UrlPattern(
  "/api/programs/:programId/timeframes/:objectId/update",
);

export const programTimeframeUrls = {
  list: programTimeframesListUrl,
  create: programTimeframesCreateUrl,
  details: programTimeframesDetailUrl,
  update: programTimeframesUpdateUrl,
};

export const programCohortsListUrl = new UrlPattern(
  "/api/programs/:programId/cohorts",
);
export const programCohortsCreateUrl = new UrlPattern(
  "/api/programs/:programId/cohorts/create",
);
export const programCohortsDetailUrl = new UrlPattern(
  "/api/programs/:programId/cohorts/:objectId/",
);
export const programCohortsUpdateUrl = new UrlPattern(
  "/api/programs/:programId/cohorts/:objectId/update",
);
export const programCohortMemberListUrl = new UrlPattern(
  "/api/programs/:programId/cohorts/:objectId/members",
);

export const programCohortUrls = {
  list: programCohortsListUrl,
  create: programCohortsCreateUrl,
  details: programCohortsDetailUrl,
  update: programCohortsUpdateUrl,
};

export const programReflectionPromptsListUrl = new UrlPattern(
  "/api/programs/:programId/reflection/prompts",
);
export const programReflectionPromptsCreateUrl = new UrlPattern(
  "/api/programs/:programId/reflection/prompts/create",
);
export const programReflectionPromptsDetailUrl = new UrlPattern(
  "/api/programs/:programId/reflection/prompts/:objectId/",
);
export const programReflectionPromptsUpdateUrl = new UrlPattern(
  "/api/programs/:programId/reflection/prompts/:objectId/update",
);

export const programReflectionPromptsUrls = {
  list: programReflectionPromptsListUrl,
  create: programReflectionPromptsCreateUrl,
  details: programReflectionPromptsDetailUrl,
  update: programReflectionPromptsUpdateUrl,
};

export const userMembershipCohortsListUrl = new UrlPattern(
  "/api/programs/:programId/user/cohorts/membership",
);
export const userLeadershipCohortsListUrl = new UrlPattern(
  "/api/programs/:programId/user/cohorts/leadership",
);

export const userRankingExerciseListUrl = new UrlPattern(
  "/api/programs/:programId/user/exercises",
);
export const userRankingExerciseCreateUrl = new UrlPattern(
  "/api/programs/:programId/user/exercises/:objectId",
);
export const userRankingExerciseDetailsUrl = new UrlPattern(
  "/api/programs/:programId/user/exercises/:objectId",
);
export const userRankingExerciseUpdateUrl = new UrlPattern(
  "/api/programs/:programId/user/exercises/:objectId",
);

export const userRankingUrls = {
  list: userRankingExerciseListUrl,
  create: userRankingExerciseCreateUrl,
  details: userRankingExerciseDetailsUrl,
  update: userRankingExerciseUpdateUrl,
};

export const userCompetencyListUrl = new UrlPattern(
  "/api/programs/:programId/user/competencies",
);
export const userCompetencyCreateUrl = new UrlPattern(
  "/api/programs/:programId/user/competencies",
);
export const userCompetencyDetailsUrl = new UrlPattern(
  "/api/programs/:programId/user/competencies/:objectId",
);
export const userCompetencyUpdateUrl = new UrlPattern(
  "/api/programs/:programId/user/competencies",
);

export const userCompetencyUrls = {
  list: userCompetencyListUrl,
  create: userCompetencyCreateUrl,
  details: userCompetencyDetailsUrl,
  update: userCompetencyUpdateUrl,
};

export const userOpportunityListUrl = new UrlPattern(
  "/api/programs/:programId/user/opportunities/activities",
);
export const userOpportunityCreateUrl = new UrlPattern(
  "/api/programs/:programId/user/opportunities/:objectId",
);
export const userOpportunityDetailsUrl = new UrlPattern(
  "/api/programs/:programId/user/opportunities/:objectId",
);
export const userOpportunityUpdateUrl = new UrlPattern(
  "/api/programs/:programId/user/opportunities/:objectId",
);

export const userOpportunityUrls = {
  list: userOpportunityListUrl,
  create: userOpportunityCreateUrl,
  details: userOpportunityDetailsUrl,
  update: userOpportunityUpdateUrl,
};

export const userActivityCreateUrl = new UrlPattern(
  "/api/programs/:programId/user/activities/",
);
export const userActivityDetailsUrl = new UrlPattern(
  "/api/programs/:programId/user/activities/:objectId",
);
export const userActivityUpdateUrl = new UrlPattern(
  "/api/programs/:programId/user/activities/:objectId",
);
export const userActivityCreateReflectionUrl = new UrlPattern(
  "/api/programs/:programId/user/activities/:objectId/reflection",
);

export const userActivityUrls = {
  list: userOpportunityListUrl,
  create: userActivityCreateUrl,
  details: userActivityDetailsUrl,
  update: userActivityUpdateUrl,
  createReflection: userActivityCreateReflectionUrl,
};

export const userReflectionListUrl = new UrlPattern(
  "/api/programs/:programId/user/reflections",
);
export const userReflectionDetailsUrl = new UrlPattern(
  "/api/programs/:programId/user/reflections/:objectId/details",
);
export const userReflectionUpdateUrl = new UrlPattern(
  "/api/programs/:programId/user/reflections/:objectId/update",
);
export const userReflectionAttachFilesUrl = new UrlPattern(
  "/api/programs/:programId/user/reflections/:objectId/attach_files",
);
export const userReflectionDetachFileUrl = new UrlPattern(
  "/api/programs/:programId/user/reflections/:objectId/detach_file",
);

export const userReflectionUrls = {
  list: userReflectionListUrl,
  details: userReflectionDetailsUrl,
  update: userReflectionUpdateUrl,
};

export const programOpportunityDateListUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/dates",
);
export const programOpportunityDateCreateUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/dates/create",
);
export const programOpportunityDateDetailsUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/dates/:objectId/details",
);
export const programOpportunityDateUpdateUrl = new UrlPattern(
  "/api/programs/:programId/opportunities/dates/:objectId/update",
);

export const programOpportunityDateUrls = {
  list: programOpportunityDateListUrl,
  create: programOpportunityDateCreateUrl,
  details: programOpportunityDateDetailsUrl,
  update: programOpportunityDateUpdateUrl,
};

export const userLevelRequestListUrl = new UrlPattern(
  "/api/programs/:programId/user/level-requests",
);
export const userLevelRequestCreateUrl = new UrlPattern(
  "/api/programs/:programId/user/level-requests/create",
);
export const userLevelRequestDetailsUrl = new UrlPattern(
  "/api/programs/:programId/user/level-requests/:objectId/details",
);
export const userLevelRequestUpdateUrl = new UrlPattern(
  "/api/programs/:programId/user/level-requests/:objectId/update",
);

export const userLevelRequestUrls = {
  list: userLevelRequestListUrl,
  create: userLevelRequestCreateUrl,
  details: userLevelRequestDetailsUrl,
  update: userLevelRequestUpdateUrl,
};

export const userLevelRequestReferenceReflectionsUrl = new UrlPattern(
  "/api/programs/:programId/user/level-requests/:objectId/reference-reflections",
);
export const userLevelRequestFileAttachmentUrl = new UrlPattern(
  "/api/programs/:programId/user/level-requests/:objectId/attach-files",
);
export const userLevelRequestFileDetachmentUrl = new UrlPattern(
  "/api/programs/:programId/user/level-requests/:objectId/detach-file",
);

export const userProgressListUrl = new UrlPattern(
  "/api/programs/:programId/user/competencies/progress",
);
export const userMicroprogressListUrl = new UrlPattern(
  "/api/programs/:programId/user/competencies/microprogress",
);
export const userRequiredCompetencyAssessmentsUrl = new UrlPattern(
  "/api/programs/:programId/user/required-competency-assessments",
);

export const googleCalendarPlannerUrl = new UrlPattern(
  "/google_calendars/programs/:programId/planner",
);
export const googleCalendarOpportunityUrl = new UrlPattern(
  "/google_calendars/programs/:programId/opportunity/:objectId",
);

export const googleCalendarUrls = {
  planner: googleCalendarPlannerUrl,
  opportunity: googleCalendarOpportunityUrl,
};

export const programLevelRequestListUrl = new UrlPattern(
  "/api/programs/:programId/level-requests",
);
export const programLevelRequestDetailsUrl = new UrlPattern(
  "/api/programs/:programId/level-requests/:objectId/show",
);
export const programLevelRequestReleaseUrl = new UrlPattern(
  "/api/programs/:programId/level-requests/release",
);

export const programLevelRequestUrls = {
  list: programLevelRequestListUrl,
  details: programLevelRequestDetailsUrl,
  release: programLevelRequestReleaseUrl,
};

export const programLevelRequestReviewListUrl = new UrlPattern(
  "/api/programs/:programId/level-requests/:requestId/reviews",
);
export const programLevelRequestReviewCreateUrl = new UrlPattern(
  "/api/programs/:programId/level-requests/:requestId/reviews/create",
);
export const programLevelRequestReviewUpdateUrl = new UrlPattern(
  "/api/programs/:programId/level-requests/:requestId/reviews/:objectId/update",
);

export const programLevelRequestReviewsUrls = {
  list: programLevelRequestReviewListUrl,
  create: programLevelRequestReviewCreateUrl,
  update: programLevelRequestReviewUpdateUrl,
};

export const programUnlockConditionsListUrl = new UrlPattern(
  "/api/programs/:programId/unlock-conditions",
);
export const programUnlockConditionsCreateUrl = new UrlPattern(
  "/api/programs/:programId/unlock-conditions/create",
);
export const programUnlockConditionsDetailUrl = new UrlPattern(
  "/api/programs/:programId/unlock-conditions/:objectId/",
);
export const programUnlockConditionsUpdateUrl = new UrlPattern(
  "/api/programs/:programId/unlock-conditions/:objectId/update",
);

export const programUnlockConditionsUrls = {
  list: programUnlockConditionsListUrl,
  create: programUnlockConditionsCreateUrl,
  details: programUnlockConditionsDetailUrl,
  update: programUnlockConditionsUpdateUrl,
};

export const programExternalReferencesListUrl = new UrlPattern(
  "/api/programs/:programId/external-references",
);
export const programExternalReferencesCreateUrl = new UrlPattern(
  "/api/programs/:programId/external-references/create",
);
export const programExternalReferencesDetailUrl = new UrlPattern(
  "/api/programs/:programId/external-references/:objectId/",
);
export const programExternalReferencesUpdateUrl = new UrlPattern(
  "/api/programs/:programId/external-references/:objectId/update",
);

export const programExternalReferencesUrls = {
  list: programExternalReferencesListUrl,
  create: programExternalReferencesCreateUrl,
  details: programExternalReferencesDetailUrl,
  update: programExternalReferencesUpdateUrl,
};

const API_V2_BASE = "/api/v2/admin/programs/:programId";
const API_V2_STUDENT_BASE = "/api/v2/student/programs/:programId";

const CAREER_CATEGORIES_BASE_API_V2_URL = `${API_V2_BASE}/career_categories`;
const CAREER_CATEGORIES_RESOURCE_API_V2_URL = `${CAREER_CATEGORIES_BASE_API_V2_URL}/:categoryId`;
export const CAREER_CATEGORIES_API_V2_URLS = {
  index: new UrlPattern(CAREER_CATEGORIES_BASE_API_V2_URL),
  options: new UrlPattern(`${CAREER_CATEGORIES_BASE_API_V2_URL}/options`),
  resource: new UrlPattern(CAREER_CATEGORIES_RESOURCE_API_V2_URL),
};

const CAREERS_BASE_API_V2_URL = `${API_V2_BASE}/careers`;
const CAREERS_RESOURCE_API_V2_URL = `${API_V2_BASE}/careers/:careerId`;
export const CAREERS_API_V2_URLS = {
  index: new UrlPattern(CAREERS_BASE_API_V2_URL),
  resource: new UrlPattern(CAREERS_RESOURCE_API_V2_URL),
  options: new UrlPattern(`${CAREERS_BASE_API_V2_URL}/options`),
};

const CAREERS_STUDENT_BASE_API_V2_URL = `${API_V2_STUDENT_BASE}/careers`;
export const CAREERS_STUDENT_API_V2_URLS = {
  index: new UrlPattern(CAREERS_STUDENT_BASE_API_V2_URL),
};

const CAREER_PROGRESS_STUDENT_BASE_API_V2_URL = `${API_V2_STUDENT_BASE}/members/:memberId/career_progress`;
const CAREER_PROGRESS_STUDENT_RESOURCE_API_V2_URL = `${API_V2_STUDENT_BASE}/members/:memberId/career_progress/:careerId`;
export const CAREER_PROGRESS_STUDENT_API_V2_URLS = {
  index: new UrlPattern(CAREER_PROGRESS_STUDENT_BASE_API_V2_URL),
  resource: new UrlPattern(CAREER_PROGRESS_STUDENT_RESOURCE_API_V2_URL),
};
const COMPETENCY_CATEGORY_BASE_API_V2_URL = `${API_V2_BASE}/competency_categories`;
export const COMPETENCY_CATEGORIES_API_V2_URLS = {
  index: new UrlPattern(COMPETENCY_CATEGORY_BASE_API_V2_URL),
  resource: new UrlPattern(
    `${COMPETENCY_CATEGORY_BASE_API_V2_URL}/:categoryId`,
  ),
  options: new UrlPattern(`${COMPETENCY_CATEGORY_BASE_API_V2_URL}/options`),
};

const COMPETENCY_CATEGORY_STUDENT_BASE_API_V2_URL = `${API_V2_STUDENT_BASE}/competency_categories`;
export const COMPETENCY_CATEGORIES_STUDENT_API_V2_URLS = {
  index: new UrlPattern(COMPETENCY_CATEGORY_STUDENT_BASE_API_V2_URL),
  options: new UrlPattern(
    `${COMPETENCY_CATEGORY_STUDENT_BASE_API_V2_URL}/options`,
  ),
};
export const UNLOCK_PROGRESS_API_V2_URLS = {
  byCategory: new UrlPattern(
    `${API_V2_STUDENT_BASE}/members/:memberId/unlock_progress/:opportunityId/by_category`,
  ),
  byCompetency: new UrlPattern(
    `${API_V2_STUDENT_BASE}/members/:memberId/unlock_progress/:opportunityId/by_competency`,
  ),
};

export const EXERCISE_STUDENT_API_V2_URLS = {
  index: new UrlPattern(`${API_V2_STUDENT_BASE}/ranking_exercises`),
};

const LEVELS_BASE_API_V2_URL = `${API_V2_BASE}/levels`;
export const LEVELS_API_V2_URLS = {
  index: new UrlPattern(LEVELS_BASE_API_V2_URL),
  options: new UrlPattern(`${LEVELS_BASE_API_V2_URL}/options`),
};

const LEVEL_SCHEME_STUDENT_BASE_API_V2_URL = `${API_V2_STUDENT_BASE}/level_scheme`;
export const LEVEL_SCHEME_STUDENT_API_V2_URLS = {
  index: new UrlPattern(LEVEL_SCHEME_STUDENT_BASE_API_V2_URL),
};

const COMPETENCY_BASE_API_V2_URL = `${API_V2_BASE}/competencies`;
export const COMPETENCIES_API_V2_URLS = {
  index: new UrlPattern(COMPETENCY_BASE_API_V2_URL),
  resource: new UrlPattern(`${COMPETENCY_BASE_API_V2_URL}/:competencyId`),
  dimensionAdvancement: new UrlPattern(
    `${COMPETENCY_BASE_API_V2_URL}/:competencyId/dimension_advancement`,
  ),
  options: new UrlPattern(`${COMPETENCY_BASE_API_V2_URL}/options`),
};

const UNLOCK_CONDITION_BASE_API_V2_URL = `${API_V2_BASE}/unlock_conditions`;
export const UNLOCK_CONDITIONS_API_V2_URLS = {
  index: new UrlPattern(UNLOCK_CONDITION_BASE_API_V2_URL),
  resource: new UrlPattern(
    `${UNLOCK_CONDITION_BASE_API_V2_URL}/:unlockConditionId`,
  ),
};

const BADGE_CATEGORY_BASE_API_V2_URL = `${API_V2_BASE}/badge_categories`;
export const BADGE_CATEGORIES_API_V2_URLS = {
  index: new UrlPattern(BADGE_CATEGORY_BASE_API_V2_URL),
  indexStudent: new UrlPattern(`${API_V2_STUDENT_BASE}/badge_categories`),
  resource: new UrlPattern(
    `${BADGE_CATEGORY_BASE_API_V2_URL}/:badgeCategoryId`,
  ),
};

const COMPETENCY_CATEGORY_PROGRESS_API_V2_URL = `${API_V2_STUDENT_BASE}/members/:memberId/category_progress`;
export const COMPETENCY_CATEGORY_PROGRESS_API_V2_URLS = {
  index: new UrlPattern(COMPETENCY_CATEGORY_PROGRESS_API_V2_URL),
  resource: new UrlPattern(
    `${COMPETENCY_CATEGORY_PROGRESS_API_V2_URL}/:categoryId`,
  ),
};

const COMPETENCY_PROGRESS_API_V2_URL = `${API_V2_STUDENT_BASE}/members/:memberId/competency_progress`;
export const COMPETENCY_PROGRESS_API_V2_URLS = {
  index: new UrlPattern(COMPETENCY_PROGRESS_API_V2_URL),
  resource: new UrlPattern(`${COMPETENCY_PROGRESS_API_V2_URL}/:competencyId`),
};

export const NAVIGATION_API_V2_URLS = {
  index: new UrlPattern(`${API_V2_BASE}/navigation`),
};

export const STUDENT_NAVIGATION_API_V2_URLS = {
  index: new UrlPattern(`${API_V2_STUDENT_BASE}/navigation`),
};

export const STUDENT_OPPORTUNITIES_API_V2_URLS = {
  index: new UrlPattern(`${API_V2_STUDENT_BASE}/opportunities`),
  categories: new UrlPattern(`${API_V2_STUDENT_BASE}/opportunities/categories`),
};

export const STUDENT_COMPETENCIES_API_V2_URLS = {
  options: new UrlPattern(`${API_V2_STUDENT_BASE}/competencies/options`),
};
